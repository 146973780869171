<template>
  <div class="page-container">
    <page-header></page-header>
    <page-table ref="table"
                show-paginate
                :show-header="true"
                :get-data="farmerPage"
                :fields="fields"
                :search-model="searchModel"
                :show-selection="true"
                show-serial-no
                :item-actions="actions"
                @onActionClick="handleItemActionClick">
        <template slot="search-items">
          <el-form-item label="状态" prop="state">
            <el-select size="small" v-model="searchModel.state" clearable placeholder="状态">
              <el-option v-for="(label, value) in stateMap"
                         :key="value"
                         :label="label"
                         :value="value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="农户信息" prop="webSearch" >
            <el-input size="small" v-model="searchModel.webSearch" placeholder="农户姓名 手机号"></el-input>
          </el-form-item>
          <el-form-item  label="所在省" prop="province" >
            <el-input size="small" v-model="searchModel.province" placeholder="所在省"></el-input>
          </el-form-item>
          <el-form-item label="所在市区" prop="city" >
            <el-input size="small" v-model="searchModel.city" placeholder="市区"  ></el-input>
          </el-form-item>
          <el-form-item label="所在县/区" prop="area">
            <el-input size="small" v-model="searchModel.area" placeholder="县/区" ></el-input>
          </el-form-item>
          <el-form-item label="详细地址" prop="address" >
            <el-input size="small" v-model="searchModel.address" clearable placeholder="详细地址" ></el-input>
          </el-form-item>
          <el-form-item label="创建时间" prop="webSearchTime">
            <el-date-picker size="small"
                            placeholder="创建时间" v-model="searchModel.webSearchTime"
                            type="date" value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
        </template>
        <template slot="page-actions">
          <div style="margin-bottom: 18px;">
            <el-button icon="el-icon-plus" @click="handleAdd" size="small" type="primary" v-permission="'farmer:add'">新增用户</el-button>
          </div>
        </template>
      <template slot="page-actions-right">
        <div style="margin-bottom: 18px;">
          <el-button style="background-color: #E6EBF0;color: #18191A;border: none;" icon="el-icon-upload2" @click="handleExport" size="small" plain>批量导出</el-button>
        </div>
      </template>
    </page-table>

    <el-dialog :title="'编辑'+pageForm.type == 1?'农户': '公共屋顶'" :visible.sync="page_dialog_visible"
        append-to-body
        :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <el-form :model="pageForm" :rules="pageRules" label-width="120px" ref="pageForm"
               v-loading="page_form_loading">

        <el-form-item label="选择类型" prop="type" v-if="!pageForm.hasOwnProperty('id')"
                      :rules="[{required: true, message: '请选择类型', trigger: 'blur'},]">
          <el-select v-model="pageForm.type" placeholder="类型">
            <el-option :label="type.label" :value="type.value" v-for="type in farmerTypeOptions" :key="type.label">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="pageForm.type == 1 ? '业主姓名': '姓名'" prop="name">
          <el-input placeholder="业主姓名" v-model="pageForm.name" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="统一信用代码" prop="unifiedCreditCode" v-if="pageForm.type == 1"
        :rules="[{required: true, message: '请输入统一信用代码', trigger: 'blur'}]">
          <el-input placeholder="业主姓名" v-model="pageForm.unifiedCreditCode" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts" v-if="pageForm.type == 1"
                      :rules="[{required: true, message: '请输入联系人', trigger: 'blur'}]">
          <el-input placeholder="联系人" v-model="pageForm.contacts" maxlength="20"></el-input>
        </el-form-item>

        <el-form-item label="身份证号" prop="idNo" v-if="pageForm.type != 1">
          <el-input placeholder="身份证号" v-model="pageForm.idNo" maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-input placeholder="电话" v-model="pageForm.phone" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item :label="pageForm.type == 1 ? '屋顶地址': '地址'" prop="areas">
          <el-cascader ref="areaCascaderRef" v-model="pageForm.areas" :options="pca_options"
                       @change="areaChange"
                       :props="{ checkStrictly: false , label: 'name', value: 'name' ,children: 'children' }"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="pageForm.address" placeholder="详细地址"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="pageForm.remarks" placeholder="备注"></el-input>
        </el-form-item>
        <div class="dialog-footer">
          <el-button @click="page_dialog_visible = false">取 消</el-button>
          <el-button @click="submit('pageForm')" type="primary">确 定</el-button>
        </div>
      </el-form>
    </el-dialog>

    <!--派单表单-->
    <el-dialog title="派单" :visible.sync="dispatch_dialog_visible"
               append-to-body :close-on-click-modal="false"
               width="35%" custom-class="min-w-750">
      <template slot="title">
        <div class="check-in-records-title">派单</div>
      </template>
      <div style="margin-top: -20px;" v-loading="dispatch_form_loading">
        <el-form inline>
          <div style="display: flex;">
            <el-form-item label="姓名" prop="webSearch">
              <el-input size="small" v-model="salesmanParams.name" placeholder="姓名" maxlength="20"></el-input>
            </el-form-item>
            <el-form-item style="margin-left: 10px;" label="手机号" prop="webSearch">
              <el-input size="small" v-model="salesmanParams.phone" placeholder="手机号" maxlength="11"></el-input>
            </el-form-item>
            <el-form-item style="margin-left: 10px;" label="" prop="webSearch">
              <el-button size="small" type="primary" icon="el-icon-search" @click="pageRefresh(1)"></el-button>
              <el-button size="small" type="primary" icon="el-icon-refresh-right" @click="resetParams"></el-button>
            </el-form-item>
          </div>
        </el-form>
        <div>
          <el-table :data="salesmanTable"
                    highlight-current-row
                    @current-change="handleCurrentChange"
                    style="width: 100%"
                    :header-row-style="{backgroundColor: '#F5F7FA'}"
                    :header-cell-style="{backgroundColor: '#F5F7FA', color: '#2F3133', fontWeight: 'normal'}">
            <el-table-column label="" width="80">
              <template #default="{row}">
                <div>
                  <el-checkbox :value="currentRow.id == row.id"></el-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="姓名" prop="name"></el-table-column>
            <el-table-column label="电话号码" prop="phone"></el-table-column>
            <el-table-column label="工单数量" prop="wordOrderNum" ></el-table-column>
          </el-table>
        </div>
        <div style="display: flex;justify-content: flex-end;" v-if="salesmanTotal">
          <el-pagination layout="prev, pager, next"
                         :total="salesmanTotal"
                         @current-change="pageRefresh"></el-pagination>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button size="small" @click="dispatch_dialog_visible = false">取 消</el-button>
        <el-button size="small" @click="dispatchSubmit" type="primary">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {
  farmerPage,
  farmerCreate,
  farmerUpdate,
  farmerDelete,
  salesmanPage,
  farmerDispatchOrder,
  farmerExport,
  farmerRoofCreate,
  farmerRoofUpdate,
  salesmanWorkOrder
} from "@/api/common"
import pcaCode from "@/utils/pca-code"
import {PROVINCE_CODES, FARMER_TYPES, USER_MANAGEMENT_STATUS} from "@/utils/config"
import downloadBlob from "@/function/downloadBlob";
import {taskStateName} from "@/utils/helper";
import StateShow from "@/components/StateShow.vue";
import '@/styles/LPopup.scss'

export default {
  name: "Farmers",
  data() {
    return {
      stateMap: USER_MANAGEMENT_STATUS,
      searchModel: {
        state: '',
        webSearch: '',
        type: '',
        province: '',
        city: '',
        area: '',
        address: '',
        webSearchTime:'',
      },
      farmerTypeOptions: FARMER_TYPES,
      farmerPage: farmerPage,
      fields: [
        {label: '姓名', key: 'name', width: 110},
        {label: '电话号码', key: 'phone', width: 120},
        {label: '身份证账号', key: 'idNo',width: 180},
        {
          label: '所在地区',
          width: 180,
          render: ({province, city, area}, h) => {
            return h('div', [province, city, area].join(''))
          }
        },
        {label: '详细地址', key: 'address'},
        {
          label: '状态',
          width:100,
          render: ({ state }) => {
            return (<StateShow status={state} text={taskStateName(state)} />)
          }
        },
        {
          label: "所属公司",
          render({createUser:user}, h) {
            const name = user?.distributor?.name || '-'
            return h('div', name)
          }
        },
        {label: '创建时间', key: 'time'},
      ],
      actions: [
        {
          action: 'dispatch',
          label: '派单',
          type: 'primary',
          permission: 'farmer:pd',
          showAction(item) {
            return item.state === 'INIT'
          }
        },
        {
          action: 'edit',
          label: '编辑',
          type: 'primary',
          permission: 'farmer:edit',
          showAction(item) {
            return !['JS', 'SUCCESS'].includes(item.state)
          }
        },
        {
          action: 'delete',
          label: '删除',
          type: 'primary',
          showAction(item) {
            return item.state === 'INIT'
          }
        },
        {action: 'view', label: '查看', type: 'primary', permission: 'farmer:see'},
      ],
      //
      page_form_loading: false,
      pageForm: {
        name: '',
        idNo: '',
        phone: '',
        provinceCode: '',
        province: '',
        city: '',
        area: '',
        areas: [],
        address: '',
        remark: '',
        type: '',
        unifiedCreditCode: '',
        contacts: '',
      },
      pageRules: {
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        idNo: [
          {required: true, message: '请输入身份证', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'},
        ],
        areas: [
          {required: true, message: '请选择地址', trigger: 'blur'},
        ],
        address: [
          {required: true, message: '请输入详细地址', trigger: 'blur'},
        ],
      },
      page_dialog_visible: false,
      pca_options: pcaCode,
      //
      dispatch_form_loading: false,
      dispatchForm: {
        farmerId: '',
        farmerName: '',
        userId: '',
        type: '',
      },
      dispatchRules: {
        userId: [
          {required: true, message: '请选择业务员', trigger: 'blur'},
        ],
      },
      dispatch_dialog_visible: false,
      searech_user_id_loading: false,
      user_options: [],
      // 派单
      currId: '',
      currItem: {},
      salesmanParams: {
        page: 1,
        // 姓名
        name: '',
        // 手机号
        phone: '',
      },
      salesmanTotal: 1,
      salesmanTable: [],
      currentRow: {}
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {

  },
  mounted() {

  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    handleAdd() {
      this.$router.push('/admin/service-farmer/edit/')
    },
    editAction(item) {
      this.$router.push('/admin/service-farmer/edit/' + item.id)
    },
    /**
     * 查看详情
     */
    viewAction(item){
      this.$router.push('/admin/service-farmer/info/' + item.id)
    },
    areaChange(val) {
      this.pageForm.province = val[0]
      this.pageForm.city = val[1]
      this.pageForm.area = val[2]
      let company = PROVINCE_CODES.find(item => {
        return item.province == val[0]
      })
      if (company) {
        this.pageForm.provinceCode = company.id
      }
    },
    submit(ref) {
      let that = this
      that.$refs[ref].validate((valid) => {
        if (!valid) {
          return false
        }
        that.page_form_loading = true
        let func
        // eslint-disable-next-line no-prototype-builtins
        if (that.pageForm.hasOwnProperty('id')) {
          func = that.pageForm.type == 1 ? farmerRoofUpdate : farmerUpdate
        } else {
            func = that.pageForm.type == 1 ? farmerRoofCreate : farmerCreate
        }
        func(that.pageForm).then(() => {
          that.page_form_loading = false
          that.$message.success('提交成功')
          that.page_dialog_visible = false
          that.$refs.table.loadData()
        }).catch(() => {
          that.page_form_loading = false
        })
      })
    },
    /**
     * 唤起派单
     */
    dispatchAction(item) {
      this.dispatch_dialog_visible = true
      // 记录派单信息
      this.currId = item.id
      this.currItem = item
      // 搜索业务员
      this.pageRefresh(1)
    },
    /**
     * 搜索业务员
     */
    pageRefresh(page){
      this.salesmanParams.page = page
      this.dispatch_form_loading = true
      salesmanWorkOrder(this.currId, this.salesmanParams).then(res => {
        this.salesmanTable = res.records
        this.salesmanTotal = Number(res.total)
      }).finally(() => {
        this.dispatch_form_loading = false
      })
    },
    /**
     * 重置派单筛选
     */
    resetParams(){
      this.salesmanParams.phone = ''
      this.salesmanParams.name = ''
      this.salesmanParams.page = 1
      this.pageRefresh(1)
    },
    /**
     * 选择派单业务员
     */
    handleCurrentChange(val){
      this.currentRow = val || {}
    },
    userIdRemoteMethod(query) {
      if (query == '') return false
      let that = this
      that.searech_user_id_loading = true
      salesmanPage({developerName: query}).then(res => {
        that.user_options = res.records
        that.searech_user_id_loading = false
      }).catch(() => {
        that.user_options = []
        that.searech_user_id_loading = false
      })
    },
    /**
     * 派单
     */
    dispatchSubmit() {
      let that = this

      //
      if (Object.keys(this.currentRow).length == 0) {
        this.$message.warning('请选择业务员')
        return
      }

      //
      that.dispatchForm = {
        farmerId: this.currItem.id,
        farmerName: this.currItem.name,
        type: this.currItem.type,
        userId: this.currentRow.id,
      }

      // 派单
      that.dispatch_form_loading = true
      farmerDispatchOrder(that.dispatchForm).then(() => {
        that.dispatch_form_loading = false
        that.$message.success('提交成功')
        that.dispatch_dialog_visible = false
        that.$refs.table.loadData()
      }).catch(() => {
        that.dispatch_form_loading = false
      })
    },
    deleteAction(item) {
      let that = this
      that.$confirm('此操作将删除农户信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        farmerDelete(item.id).then(() => {
          that.$message.success('操作成功')
          that.$refs.table.loadData()
        }).catch(() => {
          that.$message.error('操作失败')
        })
      })
    },
    handleExport() {
      let that = this, ids = []
      let selected = that.$refs.table.selection
      for (let item of selected){
        ids.push(item.id)
      }
      let data = Object.assign(that.searchModel, {
        exportIds: ids.length ? ids.join(',') : ''
      })
      farmerExport(data).then(res => {
        downloadBlob(res)
      }).catch(err => {
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
